import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { t } from "../../../../config"
import {
    nearByMeTourQuickSearch,
} from "../../../redux/actions/ToursActions"
import { TourSlider } from "../../../common/components/loadable"
import { getCurrentPosition } from "../../../common/tool/util"
import styles from "./near-by-me-slider.less"

class Component extends React.Component {
    componentDidMount() {
        getCurrentPosition(position => {
            this.props.nearByMeTourQuickSearch(
                parseFloat(position.latitude),
                parseFloat(position.longitude),
            )
        })
    }

    render() {
        if (this.props.nearByMeQuickSearch.products.length === 0) {
            return null
        }
        return (
            <div className={this.props.className}>
                <div className="container tours_title_sec">
                    <Link
                        className={styles.seeallLink}
                        to="/tours/near-me"
                    >
                        {t("See All")}
                    </Link>
                    <h2 className={styles.title}>{t("NEAR_ME_LBL")}</h2>
                </div>
                <TourSlider
                    CurrencySymbol={this.props.CurrencySymbol}
                    currency={this.props.currency}
                    tours={this.props.nearByMeQuickSearch.products}
                    showDistance
                    geo={this.props.nearByMeQuickSearch.geo}
                    listName="Home page - Near Me"
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.ToursReducers,
    }
}

export default connect(mapStateToProps, {
    nearByMeTourQuickSearch,
})(Component)
